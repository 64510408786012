import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo-home"
import SnapImg from "../components/snapimg"
import "../styles/intro.scss"
import "../styles/ionicons.min.css"
//import style from "../styles/intro.scss"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const IndexPage = () => (
  <Layout>
    <SEO
      title="Trekathlon"
      keywords={[
        `Trekking`,
        `Travel`,
        `Tips`,
        `Budget`,
        `Photography`,
        `Travel`,
      ]}
    />
    <div class="content">
      <div class="bg">
        <section class="intro" id="sintro">
          <div class="welcome">
            <h2>Libert&eacute;, Voyages,</h2>
            <h2>Randonn&eacute;es, treks et </h2>
            <h2>photographie ... </h2>
            <br></br>
            <h2>D&eacute;couvrez &aacute; travers mes exp&eacute;riences</h2>
            <h2>
              <ul>
                <li>lieux, parcours, cartes, </li>
                <li>trucs et astuces,</li>
                <li>&eacute;conomiser temps et argent, </li>
                <li>et bien plus encore !</li>
              </ul>
            </h2>
          </div>
          <div class="buttons">
            <Link to="/#scontent" class="btnfull">
              En savoir plus ...
            </Link>
            <Link to="/#sdestination" class="btnghost">
              Destinations ...
            </Link>
          </div>
        </section>
      </div>

      <section class="overview" id="scontent">
        <h2>Que trouverez-vous sur ce site ?</h2>
        <div class="mygrid">
          <div class="mycol">
            <i class="icon ion-earth"></i>
            <h3>Voyages</h3>
            <p>
              Description des différentes destinations o&uacute; je suis
              d&eacute;j&agrave; all&eacute;. Partage d'exp&eacute;rience,
              id&eacute;es d'itin&eacute;raires, budget...
            </p>
          </div>
          <div class="mycol">
            <i class="icon ion-bonfire"></i>
            <h3>Trekking</h3>
            <p>
              Description des randonn&eacute;es et treks que j'ai
              r&eacute;alis&eacute;s. J'y aborde les difficult&eacute;s
              &eacute;ventuelles, temps de parcours, possibilit&eacute;s de
              bivouac, conditions m&eacute;t&eacute;o, voire mat&eacute;riel
              &agrave; pr&eacute;voir.
            </p>
          </div>
          <div class="mycol">
            <i class="icon ion-map"></i>
            <h3>Balades</h3>
            <p>
              Dans cette partie, je d&eacute;crirai des circuits dont la
              dur&eacute;e ne d&eacute;passe pas une demi-journ&eacute;e. Les
              points d'int&eacute;r&ecirc;t &eacute;ventuels. Ces
              activit&eacute;s sont id&eacute;ales pour se d&eacute;gourdir les
              jambes.{" "}
            </p>
          </div>
          <div class="mycol">
            <i class="icon ion-cash"></i>
            <h3>&Eacute;conomies</h3>
            <p>
              Au cours de mes p&eacute;riples, je d&eacute;couvre parfois de
              bons plans, qui vous permettront d'&eacute;viter certaines
              d&eacute;penses, ou d'obtenir des prix int&eacute;ressants. Pour
              certains voyages je d&eacute;taillerai &eacute;galement toutes les
              d&eacute;penses.{" "}
            </p>
          </div>
          <div class="mycol">
            <i class="icon ion-camera"></i>
            <h3>Photographie</h3>
            <p>
              Depuis quelques ann&eacute;es, j'utilise cette activit&eacute;
              pour me concentrer sur l'instant pr&eacute;sent. Bien que ce blog
              ne soit pas consacr&eacute; exclusivement &aacute; cet art, j'y
              partagerai mes exp&eacute;riences.
            </p>
          </div>
          <div class="mycol">
            <i class="icon ion-clipboard"></i>
            <h3>Mat&eacute;riel</h3>
            <p>
              Une liste d '&eacute;quipement que j'utilise lors de mes sorties.
              Que ce soit pour le camping, mes d&eacute;placements, la
              photographie ou la vid&eacute;o.{" "}
            </p>
          </div>
        </div>
      </section>

      <section id="sdestination" class="destinations">
        <h2>Destinations</h2>
        <div class="grid">
          <SnapImg filename="Italy.jpg" alt="Italie" />
          <SnapImg filename="France.jpg" alt="France" />
          <SnapImg filename="Belgium.jpg" alt="Belgique" />
          <SnapImg filename="Scotland.jpg" alt="&Eacute;cosse" />
          <SnapImg filename="Iceland.jpg" alt="Islande" />
          <SnapImg filename="Portugal.jpg" alt="Portugal" />
          <SnapImg filename="Luxembourg.jpg" alt="Luxembourg" />
        </div>
      </section>
    </div>
  </Layout>
)

export default IndexPage
